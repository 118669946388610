<template>
  <div class="pwa-prompt" v-if="open_prompt">
    {{ translations.pwa_prompt_message }}
    <div
      class="button"
      @click="user_clicked_add"
    >
      {{ translations.pwa_prompt_message_button }}
    </div>
    <span
      class="pwa-prompt__close"
      @click="user_clicked_close"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
      </svg>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { CLIENT_STORE } from "../../constants/other"
import { SHARED_STORE } from "../../../Shared/constants/other"
import { ga_event_actions, ga_event_categories } from "../../../Shared/constants/ga_constants"
import { get_ga_instance } from "../../../../google_tracking"

export default {
  data() {
    return {
      pwa_prompt_event: {},
      open_prompt: false,
      ls_variable: "user_pwa_prompt"
    }
  },
  computed: {
    ...mapState(SHARED_STORE, ["is_mobile_device"]),
    ...mapState(CLIENT_STORE, ["translations"])
  },
  mounted() {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function() {
        navigator.serviceWorker.register("/sw.js").then((reg) => {
          console.log("Successfully registered service worker", reg);
        }).catch(function(err) {
          console.warn("Error whilst registering service worker", err);
        })
      });
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.pwa_prompt_event = e;
      this.open_prompt = true
      window.localStorage.setItem(this.ls_variable, true)
    });
  },
  methods: {
    user_clicked_add() {
      get_ga_instance().then((ga) => {
        ga.track(ga_event_categories.pwa_prompt, ga_event_actions.open)
      })

      this.open_prompt = false
      // Show the prompt
      this.pwa_prompt_event.prompt();
      // Wait for the user to respond to the prompt
      this.pwa_prompt_event.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.pwa_prompt_event = null;
        });
    },
    user_clicked_close() {
      this.open_prompt = false

      get_ga_instance().then((ga) => {
        ga.track(ga_event_categories.pwa_prompt, ga_event_actions.close)
      })
    }
  },
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;

.pwa-prompt {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 30px 10px 20px;
  line-height: 25px;
  z-index: 100;
  background-color: var(--background_heavy);
  color: $pure-white;
  font-size: 16px;

  &__close {
    height: 20px;
    line-height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      height: 100%;
      width: 100%;
      object-fit: contain;
      vertical-align: middle;
      fill: $alert-color;
    }
  }

  .button {
    margin-top: 10px;
  }
}
</style>
